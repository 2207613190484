import React, { Fragment, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./components/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import config from "./utils/config";
import { useCartContext } from "./CartContext";
import formatPrice from "./utils/formatPrice";
import { Transition } from "@headlessui/react";
import LoadingSpinner from "./components/LoadingSpinner";
import SvgLogo from "./SvgLogo";
import "./index.css";
import LineItem from "./components/LineItem";
import LineItemMobile from "./components/LineItemMobile";
import trackingService from "./analytics/trackingService";
import BundleItem from "./components/BundleItem";
import DiscountCodeForm from "./components/DiscountCodeForm";
import GiftCardForm from "./components/GiftCardForm";
import BundleItemMobile from "./components/BundleItemMobile";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import GiftItem from "./components/GiftItem";
import GiftItemMobile from "./components/GiftItemMobile";
import FreeGift from "./components/FreeGift";
import FreeGiftMobile from "./components/FreeGiftMobile";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "").catch(
  () => {
    return null;
  },
);

const Checkout = () => {
  const { checkout, checkoutTotals, totalsLoading } = useCartContext();
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (!checkout || !checkoutTotals) {
      return;
    }
    Sentry.addBreadcrumb({
      category: "checkout",
      message: "Begin Checkout",
    });
    Sentry.setContext("checkout", checkout);
    trackingService
      .trackEvent("begin_checkout", {
        checkout: checkout,
        totals: checkoutTotals,
      })
      .then(() => {});
  }, [checkout, checkoutTotals]);

  const handleShowDetails = () => {
    setShowDetails(true);
  };

  if (!checkout) {
    return null;
  }

  return (
    <>
      {!checkout.completed && (
        <div className="bg-white">
          {false && (
            <div className="fixed top-0 z-[102] left-0 w-full transform transition-all duration-500 translate-y-0">
              <div className="bg-secondary-500 absolute w-full z-10 flex flex-col justify-center h-10 text-white">
                <div className="flex justify-center text-[0.9rem] font-bold items-center mx-5 lg:mx-10 xl:mx-[90px]"></div>
              </div>
            </div>
          )}
          <div
            className="overflow-hidden fixed left-0 top-0 hidden h-full w-1/2 bg-gray-50 lg:block"
            aria-hidden="true"
          />
          <div
            className="fixed right-0 top-0 hidden h-full w-1/2 bg-white lg:block"
            aria-hidden="true"
          />
          <div className="fixed inset-x-0 top-0 flex flex-col z-[101] text-sm font-medium text-gray-900 lg:hidden">
            <div className="relative z-[102] border-t border-gray-200 bg-white px-4 sm:px-6">
              <div className="mx-auto">
                <div className="flex w-full items-center py-4 font-medium focus:outline-0">
                  <div className="mr-auto text-base">
                    <div className="flex items-center justify-flex-start text-lg font-bold text-gray-500">
                      <a
                        href={`${config.storeUrl}/cart`}
                        aria-label="Bushbuck Logo"
                        className={`flex items-center justify-center cursor-pointer text-gray-900 h-6 ${showDetails && "pointer-events-none"}`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className={`w-5 h-5 mr-2 text-gray-400 transition ${showDetails && "-translate-x-4 opacity-0"}`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>
                        <span
                          className={`text-sm transition ${showDetails && "opacity-0"}`}
                        >
                          Back
                        </span>
                      </a>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => setShowDetails(!showDetails)}
                    className="flex items-center mr-2 text-sm text-gray-400 underline decoration-dotted"
                  >
                    <span className="mr-2">
                      {showDetails ? "Close" : "Details"}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className={`w-3 h-3 transition-all transform ${showDetails && "rotate-180"}`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <Transition.Root as={Fragment} show={showDetails}>
              <div>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    onClick={() => setShowDetails(false)}
                    className="fixed inset-0 bg-black bg-opacity-25"
                  />
                </Transition.Child>

                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-y-full"
                  enterTo="translate-y-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-y-0"
                  leaveTo="-translate-y-full"
                >
                  <div className="relative bg-white px-4 py-6 sm:px-6 z-[101] overflow-y-scroll">
                    <div className="max-w-lg mx-auto mb-8">
                      <ul className="-my-6 divide-y divide-gray-200">
                        {checkout?.lineItems.map((line) => (
                          <LineItemMobile
                            key={line.variantId}
                            lineItem={line}
                            currencyCode={checkout.currencyCode}
                          />
                        ))}
                        {checkout.bundleItems.map((bundle) => (
                          <BundleItemMobile
                            key={bundle.id}
                            bundle={bundle}
                            currencyCode={checkout.currencyCode}
                          />
                        ))}
                        {checkout?.giftItems.map((giftItem) => (
                          <GiftItemMobile
                            key={giftItem.id}
                            giftItem={giftItem}
                            currencyCode={checkout.currencyCode}
                          />
                        ))}
                        {checkout?.freeGiftTier && checkoutTotals && (parseFloat(checkoutTotals.total) > checkout?.freeGiftTier.thresholdAmount || checkout.freeGiftTier.isProductGift) && (
                          <FreeGiftMobile
                            freeGift={checkout?.freeGiftTier}
                            currencyCode={checkout.currencyCode}
                          />
                        )}
                      </ul>
                    </div>
                    {checkoutTotals && (
                      <dl className="mx-auto max-w-lg space-y-6 mt-6 border-t border-gray-200 pt-6">
                        <div className="flex items-center justify-between">
                          <dt className="text-gray-600">Cart Total</dt>
                          <dd>
                            {formatPrice(
                              checkoutTotals.carttotal,
                              checkout.currencyCode,
                            )}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-gray-600">Savings</dt>
                          <dd>
                            {formatPrice(
                              checkoutTotals.discounts,
                              checkout.currencyCode,
                            )}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-gray-600">Subtotal</dt>
                          <dd>
                            {formatPrice(
                              checkoutTotals.subtotal,
                              checkout.currencyCode,
                            )}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-gray-600">Shipping</dt>
                          <dd>
                            {formatPrice(
                              checkoutTotals.shipping,
                              checkout.currencyCode,
                            )}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-gray-600">Total</dt>
                          <dd>
                            {formatPrice(
                              checkoutTotals.total,
                              checkout.currencyCode,
                            )}
                          </dd>
                        </div>
                        <div className="text-sm text-gray-700 font-light">
                          including{" "}
                          {formatPrice(
                            checkoutTotals.tax,
                            checkout.currencyCode,
                          )}{" "}
                          in taxes
                        </div>
                        {checkoutTotals?.giftCard && (
                          <>
                            <div className="flex items-center justify-between">
                              <dt className="text-gray-600">
                                Gift card applied
                              </dt>
                              {!totalsLoading && (
                                <dd className="text-gray-900">
                                  {formatPrice(
                                    checkoutTotals.giftCard,
                                    checkout.currencyCode,
                                  )}
                                </dd>
                              )}
                              {totalsLoading && (
                                <dd>
                                  <LoadingSpinner/>
                                </dd>
                              )}
                            </div>
                            {checkoutTotals?.amountDue && (
                              <div className="flex items-center justify-between">
                                <dt className="text-gray-600">Amount due</dt>
                                {!totalsLoading && (
                                  <dd className="text-gray-900">
                                    {formatPrice(
                                      checkoutTotals.amountDue,
                                      checkout.currencyCode,
                                    )}
                                  </dd>
                                )}
                                {totalsLoading && (
                                  <dd>
                                    <LoadingSpinner/>
                                  </dd>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </dl>
                    )}
                  </div>
                </Transition.Child>
              </div>
            </Transition.Root>
          </div>
          <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
            <h1 className="sr-only">Order information</h1>

            <section
              aria-labelledby="summary-heading"
              className="px-4 pb-10 pt-8 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
            >
              <div className="flex flex-col justify-center items-center lg:hidden py-16">
                <img
                  src="/bushbuck-logo-square.png"
                  alt="Bushbuck"
                  className="cursor-pointer w-36 mb-3 rounded-md p-4 bg-gray-100 object-cover object-center"
                  onClick={() => setShowDetails(true)}
                />
                <h2 className="text-lg font-medium text-gray-500 mb-2">
                  Pay Bushbuck
                </h2>
                {checkoutTotals && (
                  <h3 className="text-3xl font-medium">
                    {formatPrice(checkoutTotals?.total, checkout.currencyCode)}
                  </h3>
                )}
                <button
                  type="button"
                  className="rounded-3xl mt-2 bg-gray-100 font-medium items-center gap-2 text-sm text-black flex px-6 py-3 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  onClick={() => setShowDetails(true)}
                >
                  View details
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="3"
                    stroke="currentColor"
                    className="w-3 h-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </button>
              </div>
              {checkout && checkoutTotals && (
                <div className="mx-auto max-w-lg lg:max-w-none">
                  <PayPalScriptProvider
                    options={{
                      clientId: config.paypalClientId,
                      currency: "NZD",
                      intent: "capture",
                      disableFunding: "paylater,venmo,card",
                    }}
                  >
                    <Elements
                      stripe={stripePromise}
                      options={{
                        mode: "payment",
                        amount:
                          checkoutTotals.amountDueInCents > 0
                            ? checkoutTotals.amountDueInCents
                            : checkoutTotals.totalInCents,
                        currency: checkout.currencyCode.toLowerCase(),
                        appearance: config.stripeTheme,
                        fonts: config.fonts,
                      }}
                    >
                      <CheckoutForm onShowDetails={handleShowDetails} />
                    </Elements>
                  </PayPalScriptProvider>
                </div>
              )}
            </section>
            <div className="hidden lg:flex flex-col px-4 pb-36 pt-8 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16">
              <div className="flex items-center justify-flex-start mb-4 text-lg font-bold text-gray-500">
                <a
                  href={`${config.storeUrl}/cart`}
                  aria-label="Bushbuck Logo"
                  className={`flex group items-center justify-center cursor-pointer text-[#111] h-10`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-900"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                    />
                  </svg>
                  <SvgLogo
                    color="inherit"
                    className="absolute left-16 transition-opacity duration-200 group-hover:opacity-0"
                    style={{ width: "140px", height: "36px", margin: "auto" }}
                  />
                  <span className="absolute text-sm left-16 leading-1 hidden duration-200 group-hover:inline transition-opacity">
                    Back
                  </span>
                </a>
              </div>
              <h2
                id="summary-heading"
                className="text-lg font-bold text-gray-500 mb-2"
              >
                Pay Bushbuck
              </h2>

              {checkoutTotals && (
                <h3 className="text-4xl font-medium">
                  {formatPrice(checkoutTotals?.total, checkout.currencyCode)}
                </h3>
              )}

              <ul className="divide-y divide-gray-200 text-sm font-medium text-gray-900">
                {checkout.lineItems.map((lineItem) => (
                  <LineItem
                    key={lineItem.variantId}
                    lineItem={lineItem}
                    currencyCode={checkout.currencyCode}
                  />
                ))}
                {checkout.bundleItems.map((bundle) => (
                  <BundleItem
                    key={bundle.id}
                    bundle={bundle}
                    currencyCode={checkout.currencyCode}
                  />
                ))}
                {checkout?.giftItems.map((giftItem) => (
                  <GiftItem
                    key={giftItem.id}
                    giftItem={giftItem}
                    currencyCode={checkout.currencyCode}
                  />
                ))}
                {checkout?.freeGiftTier && checkoutTotals && (parseFloat(checkoutTotals.total) > checkout?.freeGiftTier.thresholdAmount || checkout.freeGiftTier.isProductGift) && (
                  <FreeGift
                    freeGift={checkout?.freeGiftTier}
                    currencyCode={checkout.currencyCode}
                  />
                )}
              </ul>

              <dl className="hidden space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-900 lg:block">
                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">Cart Total</dt>
                  {!totalsLoading && checkoutTotals?.carttotal && (
                    <dd>
                      {formatPrice(
                        checkoutTotals.carttotal,
                        checkout.currencyCode,
                      )}
                    </dd>
                  )}
                  {totalsLoading && (
                    <dd>
                      <LoadingSpinner />
                    </dd>
                  )}
                </div>

                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">Savings</dt>
                  {!totalsLoading && checkoutTotals?.discounts && (
                    <dd className="text-gray-900">
                      {formatPrice(
                        checkoutTotals.discounts,
                        checkout.currencyCode,
                      )}
                    </dd>
                  )}
                  {totalsLoading && (
                    <dd>
                      <LoadingSpinner />
                    </dd>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">Subtotal</dt>
                  {!totalsLoading && checkoutTotals?.subtotal && (
                    <dd>
                      {formatPrice(
                        checkoutTotals.subtotal,
                        checkout.currencyCode,
                      )}
                    </dd>
                  )}
                  {totalsLoading && (
                    <dd>
                      <LoadingSpinner />
                    </dd>
                  )}
                </div>
                <div className="flex flex-col gap-3">
                  <DiscountCodeForm />
                  <GiftCardForm />
                </div>

                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-gray-600">Shipping</dt>
                  {!totalsLoading &&
                    checkout.shippingMethod &&
                    checkoutTotals?.shipping && (
                      <dd className="text-gray-900">
                        {formatPrice(
                          checkoutTotals.shipping,
                          checkout.currencyCode,
                        )}
                      </dd>
                    )}
                  {totalsLoading && (
                    <dd>
                      <LoadingSpinner />
                    </dd>
                  )}
                  {!checkout.shippingMethod && (
                    <dd className="text-gray-600">Enter shipping address</dd>
                  )}
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-base">Total</dt>
                  {!totalsLoading && checkoutTotals?.total && (
                    <dd className="text-gray-900">
                      {formatPrice(checkoutTotals.total, checkout.currencyCode)}
                    </dd>
                  )}
                  {totalsLoading && (
                    <dd>
                      <LoadingSpinner />
                    </dd>
                  )}
                </div>
              </dl>
              {!totalsLoading && checkoutTotals?.tax && (
                <span className="text-sm text-gray-700 mt-3 font-light">
                  including{" "}
                  {formatPrice(checkoutTotals.tax, checkout.currencyCode)} in
                  taxes
                </span>
              )}

              {checkoutTotals?.giftCard && (
                <dl className="hidden space-y-6 border-t border-gray-200 mt-6 pt-6 text-sm font-medium text-gray-900 lg:block">
                  <div className="flex items-center justify-between">
                    <dt className="text-gray-600">Gift card applied</dt>
                    {!totalsLoading && (
                      <dd className="text-gray-900">
                        {formatPrice(
                          checkoutTotals.giftCard,
                          checkout.currencyCode,
                        )}
                      </dd>
                    )}
                    {totalsLoading && (
                      <dd>
                        <LoadingSpinner />
                      </dd>
                    )}
                  </div>
                  {checkoutTotals?.amountDue && (
                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">Amount due</dt>
                      {!totalsLoading && (
                        <dd className="text-gray-900">
                          {formatPrice(
                            checkoutTotals.amountDue,
                            checkout.currencyCode,
                          )}
                        </dd>
                      )}
                      {totalsLoading && (
                        <dd>
                          <LoadingSpinner />
                        </dd>
                      )}
                    </div>
                  )}
                </dl>
              )}
              <div className="flex-1"></div>
              <div className="flex justify-center gap-10 pt-5">
                <div className="flex gap-1 text-sm items-center text-gray-400 font-light">
                  <span>Powered by</span>
                  <svg
                    className="text-gray-500"
                    fill="currentColor"
                    focusable="false"
                    width="33"
                    height="15"
                    role="img"
                    aria-labelledby="stripe-title"
                  >
                    <title id="stripe-title">Stripe</title>
                    <g fillRule="evenodd">
                      <path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path>
                    </g>
                  </svg>
                </div>
                <a
                  className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
                  target="_blank"
                  href={`${config.storeUrl}/pages/privacy-policy`}
                  rel="noreferrer"
                >
                  Privacy
                </a>
                <a
                  className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
                  target="_blank"
                  href={`${config.storeUrl}/pages/returns-exchanges`}
                  rel="noreferrer"
                >
                  Returns
                </a>
                <a
                  className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
                  target="_blank"
                  href={`${config.storeUrl}/pages/contact-us`}
                  rel="noreferrer"
                >
                  Support
                </a>
              </div>
            </div>
          </div>
          <div className="flex lg:hidden justify-center gap-10">
            <a
              className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
              href={`${config.storeUrl}/pages/privacy-policy`}
            >
              Privacy
            </a>
            <a
              className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
              target="_blank"
              href={`${config.storeUrl}/pages/returns-exchanges`}
              rel="noreferrer"
            >
              Returns
            </a>
            <a
              className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
              href={`${config.storeUrl}/pages/contact-us`}
            >
              Support
            </a>
          </div>
          <div className="flex gap-1 text-sm justify-center mt-4 items-center text-gray-400 font-light pb-4">
            <span>Powered by</span>
            <svg
              className="text-gray-500"
              fill="currentColor"
              focusable="false"
              width="33"
              height="15"
              role="img"
              aria-labelledby="stripe-title"
            >
              <title id="stripe-title">Stripe</title>
              <g fillRule="evenodd">
                <path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path>
              </g>
            </svg>
          </div>
        </div>
      )}
      {checkout.completed && (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 mt-10">
          <div className="text-center">
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Order complete
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-600">
              It looks like this checkout has already completed. if you're
              having trouble, get in touch with our support team.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href={config.storeUrl}
                className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              >
                Back to store
              </a>
              <a
                href={`${config.storeUrl}/pages/contact-us`}
                className="text-sm font-semibold text-gray-900"
              >
                Contact support <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Checkout;
